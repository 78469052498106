/*
 * @description:
 * @Author: Song_Bing_Yan
 * @Date: 2022-08-16 10:06:30
 * @LastEditors: Song_Bing_Yan
 * @LastEditTime: 2023-07-13 16:19:32
 */
import api from '@/libs/request'
import { ParamsAfs } from '@pluginPackage/order/applyAfterSales/types'
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 分页查询售后工单
 * @param {any} params
 * @returns {*}
 */
export const doGetAfsOrder = (params: any) => {
    return api.get(`gruul-mall-afs/afs/order`, params)
}
/**
 * @LastEditors: Song_Bing_Yan 等待接收
 * @description: 提交售后
 * @param {any} data
 */
export const doSubmitAfss = (data: ParamsAfs) => {
    return api.post('gruul-mall-afs/afs/order', data)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 获取售后订单信息
 * @param {any} afsNo
 */
export const doGetAfssInfo = (afsNo: string) => {
    return api.get(`gruul-mall-afs/afs/order/${afsNo}`)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 撤销申请
 * @param {any} afsNo
 */
export const doPutAfssCancel = (afsNo: string) => {
    return api.put(`gruul-mall-afs/afs/order/${afsNo}/close`)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 协商历史
 * @param {any} afsNo
 */
export const doGetAfssHistory = (afsNo: string) => {
    return api.get(`gruul-mall-afs/afs/order/${afsNo}/history`)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 查询用户昵称与头像
 */
export const doGetUserData = () => {
    return api.get(`gruul-mall-uaa/uaa/user/data`)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description:查询 店铺 名称
 * @param {string} shopId
 * @returns {*}
 */
export const doGetShopInfo = (shopId: string) => {
    return api.get(`gruul-mall-shop/shop/info/base/${shopId}`)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 用户退货退款
 * @param {string} afsNo
 * @returns {*}
 */
export const doGetReturnedByAfsNo = (afsNo: string, type: 'EXPRESS_REFUND' | 'GO_STORE_REFUND', data: any) => {
    return api.put(`gruul-mall-afs/afs/order/${afsNo}/${type}/returned`, data)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 确认收货
 * @param {string} orderNo
 * @param {string} shopOrderNo
 * @param {string} packageId
 * @returns {*}
 */
export const doconfirmGoods = (orderNo: string, shopId: string) => {
    return api.put(`gruul-mall-order/order/${orderNo}/shopOrder/${shopId}/confirm`)
}
