/*
 * @description:
 * @Author: Song_Bing_Yan
 * @Date: 2022-10-18 10:11:00
 * @LastEditors: Peng_Yong
 * @LastEditTime: 2023-08-09 16:36:23
 */
import api from '@/libs/request'
import type { ChatMessage, ShopChatMessage } from '@/basePackage/pages/customerService/types'
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 提交消息（用户发起）
 * @param {string} shopId
 * @param {*} messageType
 * @param {string} message
 * @returns {*}
 */
export const doPostPigeonMessageShop = (shopId: string, data: ShopChatMessage) => {
    return api.post(`gruul-mall-carrier-pigeon/pigeon/message/shop/${shopId}/message`, data)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 客服消息列表
 * @param {string} shopId
 * @param {ChatMessage} data
 * @returns {*}
 */
export const doGetPigeonMessageShop = (params?: any) => {
    return api.get(`gruul-mall-carrier-pigeon/pigeon/platform-chat-room-messages/chat-rooms`, params)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 分页查询客服聊天室记录
 * @param {string} shopId
 * @param {ChatMessage} data
 * @returns {*}
 */
export const doGetPigeonMessageShopByShopId = (shopId: string, params?: any) => {
    return api.get(`gruul-mall-carrier-pigeon/pigeon/message/shop/${shopId}/message`, params)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 消息已读
 * @param {string} shopId
 * @param {ChatMessage} data
 * @returns {*}
 */
export const doPutPigeonMessageShopRead = (shopId: string, params?: any) => {
    return api.put(`gruul-mall-carrier-pigeon/pigeon/message/shop/${shopId}/read`, params)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 我的消息查询
 * @returns {*}
 */
export const doGetPigeonMessageMyCount = () => {
    return api.get(`gruul-mall-carrier-pigeon/pigeon/message/my/unread/count`)
}

/**
 * @LastEditors: Peng_Yong
 * @description: 用户发送信息给平台
 * @param {ChatMessage} data
 * @returns {*}
 */
export const doSendMessagePlatform = (data: ChatMessage) => {
    return api.post(`gruul-mall-carrier-pigeon/pigeon/platform-chat-room-messages/message`, data)
}
// 获取平台聊天列表
export const doGetPlatformChatRoom = (params: any) => {
    return api.get(`gruul-mall-carrier-pigeon/pigeon/platform-chat-room-messages/chat-room`, params)
}
// / 创建聊天室 pigeon/platform-chat-rooms/{shopId}/{userId
export const doGetMessagesChatRoom = (shopId: string, userId: string) => {
    return api.post(`gruul-mall-carrier-pigeon/pigeon/platform-chat-rooms/${shopId}/${userId}`)
}
