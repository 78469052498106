/*
 * @description:
 * @Author: latiao
 * @Date: 2023-05-24 20:02:33
 * @LastEditors: latiao
 * @LastEditTime: 2023-05-24 20:43:00
 */
import api from '@/libs/request'

/**
 * @LastEditors: latiao
 * @description: 用户扫码之后成为分销员 推荐人为当前code所属分销商
 */
export const doPostBinding = (code: string) => {
    return api.post(`addon-distribute/distribute/distributor/code/${code}`)
}
