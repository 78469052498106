/*
 * @description:
 * @Author: vikingShip
 * @Date: 2023-03-07 19:52:48
 * @LastEditors: vikingShip
 * @LastEditTime: 2023-03-22 15:03:28
 */
import type { AppPluginName } from '@/apis/sys/model'
import type { RoleMenuRoleJointType } from '@/apis/consumer/footprint/model'

export interface StateType {
    registerPlugin: AppPluginName[]
    roleMenus: RoleMenuRoleJointType[]
}
const defaultState: StateType = {
    registerPlugin: [],
    roleMenus: [],
}
export default defaultState
