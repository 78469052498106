<!-- eslint-disable no-unreachable -->
<!--
 * @description: 
 * @Author: vikingShip
 * @Date: 2022-04-27 13:51:40
 * @LastEditors: Song_Bing_Yan
 * @LastEditTime: 2023-06-25 17:09:09
-->
<script lang="ts">
import { useUserStore } from '@/store/modules/user'
import { TOKEN_DEL_KEY } from '@/utils/tokenConfig'
import { SET_SHOP_ID_KEY } from '@/utils/setShopId'
import { useSettingStore } from '@/store/modules/setting'
import { doPostBinding } from '@/apis/distribute/index'

uni.$on(TOKEN_DEL_KEY, () => {
    const $store = useUserStore()
    $store.DEL_TOKEN()
    // 拉起授权弹窗
    $store.PUT_AUTH_TYPE(true)
})
uni.$on(SET_SHOP_ID_KEY, (conf: any) => {
    useSettingStore().SET_SHOP_ID(conf.header['Shop-Id'])
})
export default {
    setup() {
        return {}
    },
    onLaunch({ path, query }: any) {
        //分销商绑定监听
        distributorBinding(query)
        //尝试获取分销商分销码
        distributorCodeFinder(query, path)
        //监听路由变化
        uni.addInterceptor('navigateTo', { success: routeChangeSuccess })
        uni.addInterceptor('redirectTo', { success: routeChangeSuccess })
    },
    onShow() {
        // @ts-ignore
        this.globalData.userInfo = useUserStore().getterUserInfo.info
        // @ts-ignore
        this.globalData.userInfo.token = useUserStore().getterUserInfo.token
        // @ts-ignore
        this.globalData.userInfo.token = useUserStore().getterUserInfo.refresh_token
    },
    globalData: {
        groupID: '',
        identity: 'HOST' as 'HOST' | 'USER', // HOST 主播 | USER 用户
        userInfo: {
            avatar: '',
            gender: '',
            nickname: '',
            userId: '',
            birthday: '',
            token: '',
            refresh_token: '',
        },
    },
}
function routeChangeSuccess() {
    const { options } = currentPage()
    distributorCodeFinder(options)
}
/**
 * 获取当前页面的信息
 */
function currentPage() {
    // eslint-disable-next-line no-undef
    const pages = getCurrentPages()
    const currentPage = pages[pages.length - 1]





    // @ts-ignore
    return pages[pages.length - 1].$page

}
//function 读取 分销码参数
function distributorCodeFinder(query: any, path: any) {
    let distributorCode = null
    if (query.q) {
        try {
            distributorCode = new URLSearchParams(decodeURIComponent(query.q)).get('distributorCode')
        } catch (e) {
            distributorCode = null
        }
    } else {
        distributorCode = query.distributorCode
    }
    const whiteList = ['pluginPackage/distribute/promotionCode/promotionCode']
    if (distributorCode && path && !whiteList.includes(path)) useUserStore().SET_DIS_CODE(distributorCode)
}
//分销商绑定
//监听discode变化执行绑定操作
function distributorBinding(query: any) {
    useUserStore().$subscribe(
        (mutation, state) => {
            if (state.discode && state.userInfo.token) {
                doPostBinding(state.discode).then((res) => {
                    if (res.code === 200) {
                        uni.showToast({ title: res.msg || '绑定分销成功', icon: 'none' })
                    } else {
                        uni.showToast({ title: res.msg || '绑定分销失败', icon: 'none' })
                    }
                })
                useUserStore().DEL_DIS_CODE()
            }
        },
        { immediate: true },
    )
}
</script>

<style lang="scss">
@import './uni_modules/vk-uview-ui/index.scss';
page {
    background-color: #f5f5f5;
}
</style>
