/*
 * @description:storage类
 * @Author: vikingShip
 * @Date: 2021-07-19 09:13:57
 * @LastEditors: James324
 * @LastEditTime: 2023-07-17 10:51:21
 * 123
 */
import DateUtil from './date'

class Storage {
    /**
     * 设置缓存
     * @param key 键名
     * @param data 数据
     * @param expired 持续时间（可选）
     */
    set(key: string, data: any, expired = 1) {
        // import.meta.env.VITE_BASE_URL
        uni.setStorageSync(key + import.meta.env.VITE_APP_VERSION, JSON.stringify({ data, time: +new Date(), expired: expired * 86400000 }))
    }

    /**
     * 获取缓存
     * @param key 键名
     */
    get(key: string): null {
        try {
            const { data, time = 0, expired = 0 } = JSON.parse(uni.getStorageSync(key + import.meta.env.VITE_APP_VERSION))

            if (!expired) return null

            if (time + expired > +new Date()) return data

            uni.removeStorageSync(key)
            return null
        } catch {
            return null
        }
    }

    /**
     * 清除缓存
     * @param key 键名
     */
    remove(key: string) {
        return uni.removeStorageSync(key + import.meta.env.VITE_APP_VERSION)
    }

    /**
     * 日常清空老缓存，一日一次
     */
    clear() {
        const dateUtil = new DateUtil(new Date())
        const lastClearTime = uni.getStorageSync('clearDate')
        const toady = dateUtil.getYMD(new Date())

        if (lastClearTime === toady) return

        uni.clearStorageSync()
        uni.setStorageSync('clearDate', toady)
    }
}

export default new Storage()
