/*
 * @description:
 * @Author: vikingShip
 * @Date: 2022-05-10 14:27:21
 * @LastEditors: Peng_Yong
 * @LastEditTime: 2023-07-06 09:58:35
 */
import Auth from '@/components/auth/auth.vue'
const enumComponents = [{ name: 'Auth', component: Auth }]
export const qszrUI = {
    install(Vue: import('vue').App<any>) {
        enumComponents.forEach((item) => {
            Vue.component(item.name, item.component)
        })
    },
}
