/*
 * @description:
 * @Author: vikingShip
 * @Date: 2021-11-04 14:20:04
 * @LastEditors: James324
 * @LastEditTime: 2023-07-13 20:17:00
 */
import Bxios from './bxios'
import { TOKEN_DEL_KEY } from '@/utils/tokenConfig'
import { storageKey } from '@/utils/tokenConfig'
import storage from '@/utils/storage'
import { SET_SHOP_ID_KEY } from '@/utils/setShopId'

const TOKEN_TYPE = 'bearer '
const bxios = new Bxios({
    baseUrl: import.meta.env.VITE_BASE_URL,
    header: {
        'Device-Id': uni.getSystemInfoSync().deviceId,
        'Client-Type': 'CONSUMER',
        'Shop-Id': 0,
        // #ifdef H5
        Platform: 'H5',
        // #endif
        // #ifdef MP-WEIXIN
        //@ts-ignore
        Platform: 'WECHAT_MINI_APP',
        // #endif
        // #ifdef APP-PLUS
        //@ts-ignore
        Platform: 'ANDROID',
        // #endif
    },
})

//是否是单体应用
const isSingle = import.meta.env.VITE_IS_SINGLE && import.meta.env.VITE_IS_SINGLE.toLowerCase() === 'true'
//单体应用矫正正则
const singleUrlCorrectRegex = /\/?.*?\//
//url矫正函数
const urlCorrect = (currentUrl: string) => {
    return !currentUrl ? currentUrl : isSingle ? currentUrl.replace(singleUrlCorrectRegex, '') : currentUrl
}
bxios.interceptors.request.use(
    (conf) => {
        uni.$emit(SET_SHOP_ID_KEY, conf)
        const userInfo = storage.get(storageKey)
        const { token } = userInfo || ''
        conf.header.Authorization = token ? TOKEN_TYPE + token : ''
        conf.url = urlCorrect(conf.url)
        return Promise.resolve(conf)
    },
    (err) => {
        return Promise.reject(err)
    },
)
bxios.interceptors.response.use(
    (res) => {
        if (res.code === 2 || res.code === 3 || res.code === 4) {
            // 凭据失效
            uni.$emit(TOKEN_DEL_KEY)
        }
        return Promise.resolve(res)
    },
    (err) => {
        uni.showToast({
            icon: 'none',
            title: err || '服务器内部错误',
        })
        return Promise.reject(err)
    },
)
export default bxios
