/*
 * @description:
 * @Author: Song_Bing_Yan
 * @Date: 2022-08-03 15:27:10
 * @LastEditors: Song_Bing_Yan
 * @LastEditTime: 2023-05-08 14:53:34
 */
import api from '@/libs/request'
import { ApiUserFootprint } from '@/pluginPackage/goods/commodityInfo/types'
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 根据月份查询有足迹的日期
 * @param {number} month 月份
 * @returns {*}
 */
export const doGetGoodFootprint = (month: number) => {
    return api.get(`gruul-mall-user/user/FootMark/getFootMarkBrowsDates`, { month })
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 获取用户足迹
 * @param {number} userFootprint ApiUserFootprint footMarkDate日期
 * @returns {*}
 */
export const doGetUserFootprintList = (data: any) => {
    return api.get(`gruul-mall-user/user/FootMark/list`, data)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 添加用户足迹
 * @param {number} userFootprint ApiUserFootprint
 * @returns {*}
 */
export const doPostUserFootprint = (userFootprint: ApiUserFootprint) => {
    return api.post(`gruul-mall-user/user/FootMark`, userFootprint)
}

/**
 * @LastEditors: Song_Bing_Yan
 * @description: 删除用户足迹
 * @param {number}
 * @param {number} userFootMarkStatus EMPTY 清空 DELETE 删除
 * @returns {*}
 */
export const doDelUserFootprint = (ids: any, userFootMarkStatus: 'EMPTY' | 'DELETE' = 'DELETE') => {
    return api.delete(`gruul-mall-user/user/FootMark?userFootMarkStatus=${userFootMarkStatus}`, ids)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 添加收藏
 * @param {number} assessData
 * @returns {*}
 */
export const doAddAssess = (assessData: any[]) => {
    return api.post(`gruul-mall-user/user/collect`, assessData)
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 猜你喜欢
 * @param {*} current
 * @param {*} size
 * @returns {*}
 */
export const doGetGuessYouLike = (current: number, size: number) => {
    return api.get(`gruul-mall-user/user/GuessYouLike/list`, { current, size })
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 首页获取余额
 * @returns {*}
 */
export const doGetUserBalance = () => {
    return api.get(`gruul-mall-user/user/balance`)
}
/**
 * @LastEditors: latiao
 * @description: 修改个人资料
 * @returns {*}
 */
export const doPostUserData = (updateUserName: string, commitUserGender: string, avatarimg: string, birthday: string) => {
    return api.post(`gruul-mall-uaa/uaa/user/data`, { nickname: updateUserName, gender: commitUserGender, avatar: avatarimg, birthday })
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 订单统计
 * @returns {*}
 */
export const doGetMyCount = () => {
    return api.get(`gruul-mall-order/order/my/count`)
}
export const doGetPrivateAgreement = () => {
    return api.get('gruul-mall-addon-platform/platform/privacyAgreement')
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 我的获取余额、积分、收藏数量、足迹数量
 * @returns {*}
 */
export const doGetuserPerson = () => {
    return api.get('gruul-mall-user/user/person')
}
/**
 * @LastEditors: Song_Bing_Yan
 * @description: 查询用户身份（区分权限）
 */
export const doGetRoleMenu = () => {
    return api.get(`gruul-mall-uaa/uaa/role/menu/role`)
}
