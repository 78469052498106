/*
 * @description:
 * @Author: vikingShip
 * @Date: 2022-04-27 13:51:40
 * @LastEditors: James324
 * @LastEditTime: 2023-07-27 17:37:02
 */
import { createSSRApp } from 'vue'
import { qszrUI } from '@/components/qszr-core/packages/components'
import uView from './uni_modules/vk-uview-ui'
import { getPlugin } from '@/libs/sysConfig'
import { createPinia } from 'pinia'
import { StompStarter } from '@/hooks/stomp/StompStarter'
import { getCdnUrl } from '@/utils'
const store = createPinia()
import App from './App.vue'
// global.WebSocket = window.WebSocket = WebSocketPolyfill
export function createApp() {
    // uni.$popup = new Popup()
    const app = createSSRApp(App)
    app.config.globalProperties.$getCdnUrl = getCdnUrl
    app.use(store).use(qszrUI).use(uView)
    getPlugin()
    StompStarter.start()
    return {
        app,
    }
}
