<!--
 * @description: 
 * @Author: vikingShip
 * @Date: 2022-10-12 19:36:22
 * @LastEditors: James324
 * @LastEditTime: 2023-07-29 11:32:55
-->
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useUserStore } from '@/store/modules/user'
import { useSubscribeStore } from '@/store/modules/subscribe'
import { doGetIntegralBehaviorSave } from '@/apis/plugin/integral'
import { doSignByUser } from '@/apis/sign'
import { getTemplateIds } from '@/apis/message'
import { useSettingStore } from '@/store/modules/setting'
import { doPostBinding } from '@/apis/distribute/index'
import { useAppStore } from '@/store/modules/app'

/*
 *variable
 */
const popType = ref(false)
const $userStore = useUserStore()
const $SubscribeStore = useSubscribeStore()

onMounted(() => {
    $userStore.$subscribe((arg, state) => {
        popType.value = state.needAuth
    })
})
// iphoneX以上机型底部安全距离
const safeHeight = ref(0)
const $settingStore = useSettingStore()
/*
 *lifeCircle
 */
uni.getSystemInfo({
    success: (res) => {
        if (res.safeAreaInsets) {
            safeHeight.value = res.safeAreaInsets.bottom
        }
    },
})
/*
 *function
 */
const cancel = () => {
    popType.value = false
}
const handleNavToSign = () => {

    // eslint-disable-next-line
    let routes = getCurrentPages() as any
    var page = routes[routes.length - 1].$page
    const { fullPath } = page
    uni.navigateTo({
        url: `/pages/sign/SmsAuth?route=${encodeURIComponent(fullPath)}`,
    })
    $settingStore.UPDATE_REFRESH_STATE() // 更新路由状态

}


































































</script>

<template>

    <u-modal
        v-model="popType"
        :show-cancel-button="true"
        :title-style="{ color: 'rgba(16, 16, 16, 1)', fontSize: '36rpx' }"
        confirm-color="#FA3534"
        confirm-text="手机登录"
        :confirm-style="{ borderLeft: '1px solid #f3f3f3' }"
        @cancel="cancel"
        @confirm="handleNavToSign"
    >
        <view class="login-box"> 登录后使用完整功能 </view>
    </u-modal>


















</template>

<style lang="scss" scoped>
@include b(button) {
    border-radius: 0;
    &::after {
        border: 0;
    }
    height: 100rpx;
    line-height: 100rpx;
    font-size: 32rpx;
    color: #fa3534;
    background-color: #fff;
}
@include b(login-box) {
    height: 120rpx;
    text-align: center;
    line-height: 120rpx;
}
</style>
